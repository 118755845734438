import React, { useEffect, useState } from "react";
import about1 from "../images/about1.jpeg";
import naxios from "../nepAxios";

const AdministrationDepartment = () => {
  const [data, setData] = useState([]);
  const getAdministration = async () => {
    try {
      let result = await naxios.get(`/administration`);
      if (result.data.success) {
        setData(result.data.info);
        console.log(data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getAdministration();
  }, []);
  return (
    <>
      <div>
        <div class="sitewrapper">
          <div
            class="pageheader-wrapper"
            style={{ backgroundImage: `url(${about1})` }}
          >
            <div class=" container">
              <div class="row">
                <div class="col-12 pageheader-inner">
                  <h2 class="page-title ">Administration Department</h2>
                  <ul class="bread-crumb">
                    <li class="breadcrumb-item">
                      <a href="./">Home</a>
                    </li>
                    <li class="breadcrumb-item">Administration Department</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="faculty-pg">
            <section class="sec-main">
              <div class="container">
                <div class="row">
                  <div class="sec-title text-center col-lg-9 col-12 mx-auto">
                    <h3
                      class="title-big wow  animate__bounceInDown animate__delay-200ms"
                      style={{
                        visibility: "visible",
                        "animation-name": "bounceInDown",
                      }}
                    >
                      Our Administration Department
                    </h3>
                  </div>
                </div>

                <div class="row mt-4">
                  <div
                    class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                    style={{
                      visibility: "visible",
                      "animation-name": "fadeInUp",
                    }}
                  >
                    {data &&
                      data.map((d) => (
                        <>
                          <div class="faculty-block">
                            <div class="img">
                              <img
                                src={`${process.env.REACT_APP_NEPALAYA_BASE}${d.imageUrl}`}
                                alt=""
                              />
                            </div>
                            <div class="content">
                              <h3 class="name">{d.name}</h3>
                              <span class="title">{d.designation}</span>
                              <br />
                              <span class="title">{d.email}</span>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                {/* <hr class='my-4 bg-danger border-2 border-top border-secondary' />
              <h2>School of Business</h2>
              <div class='row mt-4'>
                <div class='col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                  <div class='faculty-block'>
                    <div class='img'>
                      <img src={ShyamShrestha} alt='' />
                    </div>
                    <div class='content'>
                      <h3 class='name'></h3>
                      <span class='title'></span>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdministrationDepartment;

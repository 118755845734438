import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import NepalyaLogo from '../images/NepalayaLogo.png'
import { Information as InformationContext } from '../contexts/GeneralInformation'

function Footer() {
  const { general } = useContext(InformationContext)

  const scroll = index => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <div>
      <footer class='footer'>
        <div class='footer-main'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-4 col-md-12'>
                <div class='footer-about-content'>
                  <div class='logo'>
                    <img src={NepalyaLogo} alt='' />
                  </div>
                  <p class='mt-2 text-white'>{general?.footerText}</p>
                </div>
              </div>
              <div class='col-lg-4 col-md-6'>
                <div class='usefull-link'>
                  <h5 class='text-white'>Quick Links</h5>
                  <ul>
                    <li onClick={() => scroll()}>
                      {' '}
                      <Link to={'/'}>
                        {' '}
                        <i class='fa fa-angle-right'></i> Home{' '}
                      </Link>
                    </li>
                    <li onClick={() => scroll()}>
                      {' '}
                      <Link to={'/notices'}>
                        {' '}
                        <i class='fa fa-angle-right'></i> All Notice{' '}
                      </Link>
                    </li>
                    <li onClick={() => scroll()}>
                      {' '}
                      <Link to={'/events'}>
                        {' '}
                        <i class='fa fa-angle-right'></i> All Events
                      </Link>
                    </li>

                    <li onClick={() => scroll()}>
                      {' '}
                      <Link to={'/faculty'}>
                        {' '}
                        <i class='fa fa-angle-right'></i> Faculty Members
                      </Link>
                    </li>
                    <li onClick={() => scroll()}>
                      {' '}
                      <Link to={'/contact'}>
                        {' '}
                        <i class='fa fa-angle-right'></i> Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class='col-lg-4 col-md-6'>
                <div class='keep-touch'>
                  <h5 class='text-white'>Contact Us</h5>
                  <div class='address'>
                    <ul>
                      <li>
                        {' '}
                        <i class='fas fa-map-marker'></i>
                        <span>{general?.address}</span>{' '}
                      </li>
                      <li>
                        {' '}
                        <i class='fas fa-phone'></i>
                        <a href='tel:977015234279'>{general?.contact}</a>{' '}
                      </li>
                      <li>
                        {' '}
                        <i class='fas fa-envelope'></i>
                        <a href={`mailto:${general?.email}`}>{general?.email}</a>{' '}
                      </li>
                    </ul>
                  </div>
                  <div class='social'>
                    <ul>
                      <li>
                        <a href='https://www.facebook.com/Nepalaya.edu.np/'>
                          {' '}
                          <i class='fab fa-facebook-f'></i>
                        </a>
                      </li>
                      <li>
                        <a href='https://twitter.com/nepalayacol'>
                          {' '}
                          <i class='fab fa-twitter'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='copyright'>
          <div class='container'>
            <div class='row align-items-center'>
              <div class='col-lg-6 col-md-12 d-flex justify-content-center justify-content-lg-start mb-md-2'>
                <p>{general?.copyrightText}</p>
              </div>
              <div class='col-lg-6 col-md-12 d-flex justify-content-center justify-content-lg-end'>
                <p class='design'>
                  Designed &amp; Hosted: <a href='https://digitechnologynepal.com/'>DIGI TECHNOLOGY NEPAL</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer

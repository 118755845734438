import axios from 'axios'

// const csrfToken = () => {
//   const cookies = decodeURIComponent(document.cookie).split(';')
//   const token = cookies.find(cookie => cookie.includes('CSRF-Token'))
//   if (token) {
//     return token.split('=')[1]
//   }
// }
const instance = axios.create({
  baseURL: process.env.REACT_APP_SCHOOLWORKS_BASE_URI,
})

export default instance

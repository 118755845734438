import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import NepalyaLogo from "../images/NepalayaLogo.png";
import { Information as InformationContext } from "../contexts/GeneralInformation";
import ContentView from "react-froala-wysiwyg/FroalaEditorView";
import naxios from "../nepAxios";
import { MESSAGE_BOARD } from "../constants/customApi";

function Header() {
  let toggle = document.querySelector("nav-toggle");
  const { general } = useContext(InformationContext);

  const [open, setOpen] = useState(false);

  const [messageBoard, setMessageBoard] = useState(MESSAGE_BOARD);
  const [departments, setDepartments] = useState([]);

  const getMessageBoard = async () => {
    try {
      let result = await naxios.get("/messageboard");
      if (result.data.success) {
        setMessageBoard(result.data.messageBoard);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDepartment = async () => {
    try {
      let result = await naxios.get("/department");
      if (result.data.success) {
        setDepartments(result.data.department);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toggleSidebar = () => {
    if (open === true) {
      setOpen(false);
    } else setOpen(true);
  };

  useEffect(() => {
    getMessageBoard();
    getDepartment();
  }, []);

  return (
    <div>
      <header id="header" class="header">
        <div id="stickymenu" class="stickymenu">
          <div class="header-mid">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-4 site-brand-col">
                  <div class="logo-sec">
                    <Link to={"/"}>
                      <img src={NepalyaLogo} alt="logo" />
                    </Link>
                  </div>
                </div>
                <div class="col-lg-9 col-8 header-menu-col">
                  <div class="info-mid">
                    <div class="top-info-box">
                      <div class="info-icon">
                        <i class="fas fa-phone" aria-hidden="true"></i>
                      </div>
                      <div class="info-text">
                        <a
                          href="tel:977015375203"
                          title="Click to Call"
                          class="bottom-text white_to_blue"
                        >
                          {general?.contact}
                        </a>
                      </div>
                    </div>
                    <div
                      class="top-info-box border rounded"
                      style={{
                        border: "1px solid #7a89ac",
                        color: "orange",
                      }}
                    >
                      <div class="info-icon" style={{ color: "orange" }}>
                        <i
                          style={{ color: "orange" }}
                          class="fa fa-scroll"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="info-text">
                        <a
                          href={"https://schoolworkspro.com/npl/admission"}
                          title=""
                          class="bottom-text white_to_blue"
                          style={{ color: "orange" }}
                        >
                          Admission Form
                        </a>
                      </div>
                    </div>
                    {/* <div class='top-info-box'>
                      <div class='info-icon'>
                        <i class='fa fa-university' aria-hidden='true'></i>
                      </div>
                      <div class='info-text'>
                        <a href='javascript:void(0)' title='' class='bottom-text white_to_blue'>
                          e-Portal
                        </a>
                      </div>
                    </div> */}

                    <div class="info-payment top-info-box">
                      <div class="info-text ">
                        <a
                          href={"https://schoolworkspro.com/"}
                          title=""
                          class="bottom-text payment-btn link-btn btn-style-2"
                        >
                          <i class="fa fa-university" aria-hidden="true"></i>{" "}
                          E-Portal
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={toggleSidebar}
                    class={`nav-toggle app-md ms-3 ${open && "open"} `}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-lower">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 header-menu-wrapper">
                  <nav class="menu-sec">
                    <div class="nav-menu-list">
                      <ul class="nav-menu checklink navul">
                        <li class="active">
                          <Link to={"/"}>Home</Link>
                        </li>
                        <li class="submenu">
                          <a href="javascript:void(0)">Our Campus</a>
                          <ul class="submenu-inner">
                            <li class="javascript:void(0)">
                              <Link to={"/about"}>
                                <span>About Us</span>
                                <i class="ms-3 fas fa-angle-right"></i>
                              </Link>
                              <ul class="submenu-inner-inner">
                                <li class="javascript:void(0)">
                                  <Link to={"/about"}>Introduction</Link>
                                </li>
                                <li class="javascript:void(0)">
                                  <Link to={"/reasons-to-join-nepalaya"}>
                                    Why Nepalaya
                                  </Link>
                                </li>
                                <li class="javascript:void(0)">
                                  <Link to={"/bod"}>BOD</Link>
                                </li>
                              </ul>
                            </li>
                            {/* <li class='javascript:void(0)'>
                              <Link to={'/reasons-to-join-nepalaya'}>Why Nepalaya</Link>
                              <ul class='submenu-inner'></ul>
                            </li> */}
                            <li class="javascript:void(0)">
                              {/* <Link to={'message/message-ceo'}>
                                <span>Message Board</span>
                                <i class='ms-3 fas fa-angle-right'></i>
                              </Link> */}
                              <Link to={"message/principal"}>
                                <span>Message Board</span>
                                <i class="ms-3 fas fa-angle-right"></i>
                              </Link>
                              <ul class="submenu-inner-inner">
                                {/* <li class='javascript:void(0)'>
                                  <Link to={'message/message-ceo'}>CEO</Link>
                                </li> */}
                                {messageBoard &&
                                  messageBoard.map((msg) => {
                                    return (
                                      <li class="javascript:void(0)">
                                        <Link to={`message/${msg.slug}`}>
                                          {msg.keyName}
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                            <li class="javascript:void(0)">
                              {/* <Link to={'message/message-ceo'}>
                                <span>Message Board</span>
                                <i class='ms-3 fas fa-angle-right'></i>
                              </Link> */}
                              <Link to={"/faculty"}>
                                <span>Faculty</span>
                                <i class="ms-3 fas fa-angle-right"></i>
                              </Link>
                              <ul class="submenu-inner-inner">
                                {/* <li class='javascript:void(0)'>
                                  <Link to={'message/message-ceo'}>CEO</Link>
                                </li> */}
                                {departments &&
                                  departments.map((dpt) => {
                                    return (
                                      <li class="javascript:void(0)">
                                        <Link to={`faculty/${dpt.slug}`}>
                                          {dpt.title}
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                            <li class="javascript:void(0)">
                              <Link to={"/administration-department"}>
                                <span>Administration Department</span>
                                {/* <i class="ms-3 fas fa-angle-right"></i> */}
                              </Link>
                              
                            </li>
                          </ul>
                        </li>
                        <li class="javascript:void(0) ">
                          <Link to={"/academic"}>Academics</Link>
                          <ul class="submenu-inner"></ul>
                        </li>
                        <li class="submenu">
                          <Link to={"/student-hub"}>Student Hub</Link>

                          <ul class="submenu-inner">
                            <li class="javascript:void(0)">
                              <Link to={"/students/voice"}>Students Voice</Link>
                              <ul class="submenu-inner"></ul>
                            </li>
                            <li class="javascript:void(0)">
                              <Link to={"/eca"}>ECA</Link>
                              <ul class="submenu-inner"></ul>
                            </li>
                            <li class="javascript:void(0)">
                              <Link to={"/nepalaya-club"}>Nepalaya Club</Link>
                              <ul class="submenu-inner"></ul>
                            </li>
                            <li class="javascript:void(0)">
                              <Link to={"/alumni"}>Alumni</Link>
                            </li>
                          </ul>
                        </li>
                        <li class="submenu">
                          <a href="javascript:void(0)">Admission</a>
                          <ul class="submenu-inner">
                            <li class="javascript:void(0)">
                              <a
                                href={
                                  "https://schoolworkspro.com/npl/enquiry-form"
                                }
                              >
                                Enquiry Form
                              </a>
                              <ul class="submenu-inner"></ul>
                            </li>
                            <li class="javascript:void(0)">
                              <a
                                href={
                                  "https://schoolworkspro.com/npl/admission"
                                }
                              >
                                Admission Form
                              </a>
                              <ul class="submenu-inner"></ul>
                            </li>
                          </ul>
                        </li>
                        <li class="javascript:void(0)">
                          <Link to={"/events"}>Events</Link>
                        </li>
                        <li class="javascript:void(0)">
                          <Link to={"/notices"}>Notices</Link>
                        </li>
                        <li class="submenu">
                          <a href="javascript:void(0)">More</a>
                          <ul class="submenu-inner">
                            <li class="javascript:void(0)">
                              <Link to={"/downloads"}>Downloads</Link>
                              <ul class="submenu-inner"></ul>
                            </li>
                            <li class="javascript:void(0)">
                              <Link to={"/gallery"}>Gallery</Link>
                              <ul class="submenu-inner"></ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class={`outer-nav  ${open && "open"}`}>
          <div onClick={toggleSidebar} class={`nav-toggle ${open && "open"}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <nav id="outermenu ">
            <ul class="nav-menu checklink navul">
              <li class="active">
                <Link to={"/"}>Home</Link>
              </li>
              <li class="submenu mainmenu">
                <a href="javascript:void(0)">Our Campus</a>
                <ul class="submenu-inner">
                  <li onClick={toggleSidebar} class="">
                    <Link to={"/about"}>About Us</Link>
                  </li>
                  <li onClick={toggleSidebar} class="">
                    <Link to={"/reasons-to-join-nepalaya"}>Why Nepalaya</Link>
                  </li>
                  {/* <li onClick={toggleSidebar} class=''>
                    <Link to={'message/message-ceo'}>Message from the CEO</Link>
                  </li> */}
                  {messageBoard &&
                    messageBoard.map((msg) => {
                      return (
                        <li onClick={toggleSidebar} class="">
                          <Link to={`message/${msg.slug}`}>{msg.keyName}</Link>
                        </li>
                      );
                    })}
                  <li onClick={toggleSidebar} class="">
                    <Link to={"/bod"}>BOD</Link>
                  </li>
                  <li onClick={toggleSidebar} class="">
                    <Link to={"/faculty"}>Faculty Members</Link>
                  </li>
                </ul>
              </li>
              <li class="submenu mainmenu">
                <a href="javascript:void(0)">Message</a>
                <ul class="submenu-inner">
                  {messageBoard &&
                    messageBoard.map((msg) => {
                      return (
                        <li onClick={toggleSidebar}>
                          <Link to={`message/${msg.slug}`}>{msg.keyName}</Link>
                        </li>
                      );
                    })}
                </ul>
              </li>
              <li onClick={toggleSidebar} class="">
                <Link to={"/academic"}>Academics</Link>
              </li>
              <li class="submenu mainmenu">
                <Link to={"/student-hub"}>Student Hub</Link>
                <ul class="submenu-inner">
                  <li onClick={toggleSidebar} class="">
                    <Link to={"/students/voice"}>Students Voice</Link>
                  </li>
                  <li onClick={toggleSidebar} class="">
                    <Link to={"/eca"}>ECA</Link>
                  </li>
                  <li onClick={toggleSidebar} class="">
                    <Link to={"/nepalaya-club"}>Nepalaya Club</Link>
                  </li>
                  <li onClick={toggleSidebar} class="">
                    <Link to="/almuni">Alumni</Link>
                  </li>
                </ul>
              </li>
              <li class="submenu mainmenu">
                <Link to={"/faculty"}>Faculty members</Link>
                <ul class="submenu-inner">
                  {departments &&
                    departments.map((dpt) => {
                      return (
                        <li onClick={toggleSidebar} class="">
                          <Link to={`faculty/${dpt.slug}`}>{dpt.title}</Link>
                        </li>
                      );
                    })}
                </ul>
              </li>
              <li onClick={toggleSidebar} class="">
                <a href={"https://schoolworkspro.com/npl/enquiry-form"}>
                  Enquiry Form
                </a>
              </li>
              <li onClick={toggleSidebar} class="">
                <a href={"https://schoolworkspro.com/npl/admission"}>
                  Admission Form
                </a>
              </li>
              <li onClick={toggleSidebar} class="">
                <Link to={"/downloads"}>Download</Link>
              </li>
              <li onClick={toggleSidebar} class="">
                <Link to={"/notices"}>Notices</Link>
              </li>
              <li onClick={toggleSidebar} class="">
                <Link to={"/events"}>Events</Link>
              </li>
              <li onClick={toggleSidebar} class="">
                <Link to={"/gallery"}>Gallery</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;

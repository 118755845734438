import React, { useState, useEffect } from 'react'
import Principal from '../images/principal.jpeg'
import Avatar from '../images/avatar.png'
import about1 from '../images/about1.jpeg'
import { Team } from '../ImageExport'
import naxios from '../nepAxios'
import ContentView from 'react-froala-wysiwyg/FroalaEditorView'
import { useParams } from 'react-router-dom'
import { MESSAGE_BOARD } from '../constants/customApi'

function MessageBoard() {
  const [messageBoard, setMessageBoard] = useState(null)
  const { slug } = useParams()

  const getMessageBoard = async () => {
    try {
      let result = await naxios.get(`/messageboard/${slug}`)
      if (result.data.success) {
        setMessageBoard(result.data.messageBoard)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const message = MESSAGE_BOARD
    let msg = message.find(c => c.slug === slug)
    if (msg) {
      setMessageBoard(msg)
    }
    getMessageBoard()
  }, [slug])

  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>{messageBoard?.keyName}</h2>
                <ul class='bread-crumb'>
                  <li class='breadcrumb-item'>
                    <a href='https://nepalaya.edu.np'>Home</a>
                  </li>
                  <li class='breadcrumb-item'>{messageBoard?.keyName}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class='bod-pg'>
          <section class='chairmain-sec sec-main'>
            <div class='container'>
              <div class='row chairmain-sec-wrapper wow  animate__fadeInUp animate__animated' style={{ visibility: 'visible', 'animation-name': 'fadeInUp' }}>
                <div class='col-lg-4'>
                  <div class='img'>
                    <img src={`${process.env.REACT_APP_NEPALAYA_BASE}${messageBoard?.imageUrl}`} alt='messageboard' />
                  </div>
                </div>
                <div class='col-lg-8'>
                  <div class='content' style={{ 'text-align': 'justify' }}>
                    <ContentView model={messageBoard?.content} />
                  </div>
                  <div class='content-meta'>
                    <h4 class='name'>{messageBoard?.name}</h4>
                    <h5 class='position'>{messageBoard?.primaryTitle}</h5>
                    <h6 class='position'>{messageBoard?.secondary}</h6>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default MessageBoard

import React from "react";
import { Link } from "react-router-dom";
import about1 from "../images/about1.jpeg";
import KrishnaDotel from "../images/faculty/krishnadotel.jpeg";
import DAMBARPANDEY from "../images/faculty/dambarpandey.jpeg";
import ShailaMitra from "../images/faculty/shailamitra.jpeg";
import Avatar from "../images/avatar.png";

function StudentsClub() {
  const renderCulturalClub = () => {
    return (
      <>
        <hr class="my-4 bg-danger border-2 border-top border-secondary" />
        <div class="row mt-4">
          <div
            class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
            style={{
              visibility: "hidden",
              "animation-name": "none",
            }}
          >
            <div class="faculty-block">
              <div class="img">
                <img src={Avatar} alt="" />
              </div>
              <div class="content">
                <h3 class="name">Name</h3>
                <span class="title">President</span>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
            style={{
              visibility: "visible",
              "animation-name": "fadeInUp",
            }}
          >
            <div class="faculty-block">
              <div class="img">
                <img src={Avatar} alt="" />
              </div>
              <div class="content">
                <h3 class="name">Name</h3>
                <span class="title">Vice-president</span>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4 bg-danger border-2 border-top border-secondary" />
        <h2>Members</h2>
        <div class="row mt-4">
          <div
            class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
            style={{
              visibility: "hidden",
              "animation-name": "none",
            }}
          >
            <div class="faculty-block">
              <div class="img">
                <img src={Avatar} alt="" />
              </div>
              <div class="content">
                <h3 class="name">Name</h3>
                <span class="title">Member</span>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
            style={{
              visibility: "visible",
              "animation-name": "fadeInUp",
            }}
          >
            <div class="faculty-block">
              <div class="img">
                <img src={Avatar} alt="" />
              </div>
              <div class="content">
                <h3 class="name">Name</h3>
                <span class="title">Member</span>
              </div>
            </div>
          </div>
          <div
            class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
            style={{
              visibility: "visible",
              "animation-name": "fadeInUp",
            }}
          >
            <div class="faculty-block">
              <div class="img">
                <img src={Avatar} alt="" />
              </div>
              <div class="content">
                <h3 class="name">Name</h3>
                <span class="title">Member</span>
              </div>
            </div>
          </div>
          <div
            class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
            style={{
              visibility: "visible",
              "animation-name": "fadeInUp",
            }}
          >
            <div class="faculty-block">
              <div class="img">
                <img src={Avatar} alt="" />
              </div>
              <div class="content">
                <h3 class="name">Name</h3>
                <span class="title">Member</span>
              </div>
            </div>
          </div>
          <div
            class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
            style={{
              visibility: "visible",
              "animation-name": "fadeInUp",
            }}
          >
            <div class="faculty-block">
              <div class="img">
                <img src={Avatar} alt="" />
              </div>
              <div class="content">
                <h3 class="name">Name</h3>
                <span class="title">Member</span>
              </div>
            </div>
          </div>
          <div
            class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
            style={{
              visibility: "visible",
              "animation-name": "fadeInUp",
            }}
          >
            <div class="faculty-block">
              <div class="img">
                <img src={Avatar} alt="" />
              </div>
              <div class="content">
                <h3 class="name">Name</h3>
                <span class="title">Member</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div class="sitewrapper">
        <div
          class="pageheader-wrapper"
          style={{ backgroundImage: `url(${about1})` }}
        >
          <div class=" container">
            <div class="row">
              <div class="col-12 pageheader-inner">
                <h2 class="page-title ">Students Club</h2>
                <ul class="bread-crumb" id="academics">
                  <li class="breadcrumb-item">
                    <Link to={"/home"}>Home</Link>
                  </li>
                  <li class="breadcrumb-item">Students Club</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="academics-pg sec-main">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div class="academics-left">
                  <ul
                    class="nav nav-tabs academics-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item " role="presentation">
                      <a
                        class="nav-link active"
                        id="1-tab"
                        data-bs-toggle="tab"
                        href="#content-1"
                        role="tab"
                        aria-controls="content-1"
                        aria-selected="true"
                      >
                        Co-ordination Club
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="2-tab"
                        data-bs-toggle="tab"
                        href="#content-2"
                        role="tab"
                        aria-controls="content-2"
                        aria-selected="true"
                      >
                        Cultural Club
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="3-tab"
                        data-bs-toggle="tab"
                        href="#content-3"
                        role="tab"
                        aria-controls="content-3"
                        aria-selected="true"
                      >
                        Eco Club
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="4-tab"
                        data-bs-toggle="tab"
                        href="#content-4"
                        role="tab"
                        aria-controls="content-4"
                        aria-selected="true"
                      >
                        Literature Club
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="5-tab"
                        data-bs-toggle="tab"
                        href="#content-5"
                        role="tab"
                        aria-controls="content-5"
                        aria-selected="true"
                      >
                        Social Club
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="6-tab"
                        data-bs-toggle="tab"
                        href="#content-6"
                        role="tab"
                        aria-controls="content-6"
                        aria-selected="true"
                      >
                        Sports Club
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="7-tab"
                        data-bs-toggle="tab"
                        href="#content-7"
                        role="tab"
                        aria-controls="content-7"
                        aria-selected="true"
                      >
                        Health Club
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="8-tab"
                        data-bs-toggle="tab"
                        href="#content-8"
                        role="tab"
                        aria-controls="content-8"
                        aria-selected="true"
                      >
                        Arts & Music Club
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="academics-right">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="content-1"
                      role="tabpanel"
                      aria-labelledby="1-tab"
                    >
                      <div class="programs-tab">
                        <div class="sec-title">
                          <h3 class="title-big">Co-ordination Club</h3>
                        </div>
                        <div
                          class="program-desc"
                          style={{ "text-align": "justify" }}
                        >
                          <hr class="my-4 bg-danger border-2 border-top border-secondary" />
                          <div class="row mt-4">
                            <div
                              class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                              style={{
                                visibility: "hidden",
                                "animation-name": "none",
                              }}
                            >
                              <div class="faculty-block">
                                <div class="img">
                                  <img src={KrishnaDotel} alt="" />{" "}
                                </div>
                                <div class="content">
                                  <h3 class="name">Krishna Prasad Dotel</h3>
                                  <span class="title">President</span>{" "}
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                              style={{
                                visibility: "visible",
                                "animation-name": "fadeInUp",
                              }}
                            >
                              <div class="faculty-block">
                                <div class="img">
                                  <img src={Avatar} alt="" />
                                </div>
                                <div class="content">
                                  <h3 class="name">Name here</h3>
                                  <span class="title">Vice-president</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr class="my-4 bg-danger border-2 border-top border-secondary" />
                          <h2>Co-ordinators</h2>
                          <div class="row mt-4">
                            <div
                              class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                              style={{
                                visibility: "hidden",
                                "animation-name": "none",
                              }}
                            >
                              <div class="faculty-block">
                                <div class="img">
                                  <img src={Avatar} alt="" />
                                </div>
                                <div class="content">
                                  <h3 class="name">Amrit Prasad Dhakal</h3>
                                  <span class="title">School Co-ordinator</span>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                              style={{
                                visibility: "visible",
                                "animation-name": "fadeInUp",
                              }}
                            >
                              <div class="faculty-block">
                                <div class="img">
                                  <img src={DAMBARPANDEY} alt="" />
                                </div>
                                <div class="content">
                                  <h3 class="name">Dambar Pandey</h3>
                                  <span class="title">+2 Co-ordinator</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                              style={{
                                visibility: "visible",
                                "animation-name": "fadeInUp",
                              }}
                            >
                              <div class="faculty-block">
                                <div class="img">
                                  <img src={ShailaMitra} alt="" />
                                </div>
                                <div class="content">
                                  <h3 class="name">Shaila Mitra Neupane</h3>
                                  <span class="title">
                                    BSc.CSIT/BCA Coordinator
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                              style={{
                                visibility: "visible",
                                "animation-name": "fadeInUp",
                              }}
                            >
                              <div class="faculty-block">
                                <div class="img">
                                  <img src={Avatar} alt="" />
                                </div>
                                <div class="content">
                                  <h3 class="name">Shanti Rai</h3>
                                  <span class="title">
                                    BBS/BBM/MBS Coordinator
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="content-2"
                      role="tabpanel"
                      aria-labelledby="2-tab"
                    >
                      <div class="programs-tab">
                        <div class="sec-title">
                          <h3 class="title-big">Cultural Club</h3>
                        </div>
                        <div
                          class="program-desc"
                          style={{ "text-align": "justify" }}
                        >
                          {renderCulturalClub()}
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="content-3"
                      role="tabpanel"
                      aria-labelledby="3-tab"
                    >
                      <div class="programs-tab">
                        <div class="sec-title">
                          <h3 class="title-big">Eco Club</h3>
                        </div>
                        <div
                          class="program-desc"
                          style={{ "text-align": "justify" }}
                        >
                          {renderCulturalClub()}
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="content-4"
                      role="tabpanel"
                      aria-labelledby="4-tab"
                    >
                      <div class="programs-tab">
                        <div class="sec-title">
                          <h3 class="title-big">Literature Club</h3>
                        </div>
                        <div
                          class="program-desc"
                          style={{ "text-align": "justify" }}
                        >
                          {renderCulturalClub()}
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="content-5"
                      role="tabpanel"
                      aria-labelledby="5-tab"
                    >
                      <div class="programs-tab">
                        <div class="sec-title">
                          <h3 class="title-big">Social Club</h3>
                        </div>
                        <div
                          class="program-desc"
                          style={{ "text-align": "justify" }}
                        >
                          {renderCulturalClub()}
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="content-6"
                      role="tabpanel"
                      aria-labelledby="6-tab"
                    >
                      <div class="programs-tab">
                        <div class="sec-title">
                          <h3 class="title-big">Sports Club</h3>
                        </div>
                        <div
                          class="program-desc"
                          style={{ "text-align": "justify" }}
                        >
                          {renderCulturalClub()}
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="content-7"
                      role="tabpanel"
                      aria-labelledby="7-tab"
                    >
                      <div class="programs-tab">
                        <div class="sec-title">
                          <h3 class="title-big">Health Club</h3>
                        </div>
                        <div
                          class="program-desc"
                          style={{ "text-align": "justify" }}
                        >
                          {renderCulturalClub()}
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="content-8"
                      role="tabpanel"
                      aria-labelledby="8-tab"
                    >
                      <div class="programs-tab">
                        <div class="sec-title">
                          <h3 class="title-big">Arts & Club</h3>
                        </div>
                        <div
                          class="program-desc"
                          style={{ "text-align": "justify" }}
                        >
                          {renderCulturalClub()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsClub;

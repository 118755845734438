import React from 'react'
import { Link } from 'react-router-dom'
import SharingClass from '../images/sharingclass.jpeg'
import StudentImage from '../images/student-img.png'
import AboutImage from '../images/about.jpeg'
import PdfImage from '../images/pdf.png'
import about1 from '../images/about1.jpeg'
import { Program } from '../ImageExport'

import Semester1 from '../pdf/20220106073906.pdf'
import Semester2 from '../pdf/20220106073948.pdf'
import Semester3 from '../pdf/20220106074010.pdf'
import Semester4 from '../pdf/20220106074037.pdf'
import Semester5 from '../pdf/20220106074056.pdf'
import Semester6 from '../pdf/20220106074121.pdf'
import Semester7 from '../pdf/20220106074203.pdf'
import Semester8 from '../pdf/20220106074203.pdf'

function BBM() {
  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper pageheader-course' style={{ backgroundImage: `url(${Program.BBM})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>BBM PROGRAM</h2>
                <div class='course-header-desc'>
                  <div class='course-detail'>
                    <div class='icon'>
                      <i class='fas fa-history'></i>
                    </div>
                    <div class='title'>Duration</div>
                    <div class='content'>4 years</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='single-course-pg'>
          <section class='course-info-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-9'>
                  <div class='course-info'>
                    <div class='title'>
                      <h3>BBM PROGRAM</h3>
                    </div>
                    <div class='content' style={{ 'text-align': 'justify' }}>
                      <p>
                        Bachelor of Business Management (BBM) program is a four years undergraduate degree of Tribhuvan University. The main objective of this degree is to provide students with
                        essential skills to carter their leadership, management and entrepreneurial skills. This program covers 120 credit hours of study in the entire four year eight-semesters course
                        including six months practical-based industry placement/internships and project work.
                      </p>

                      <p>
                        <strong>Eligibility:</strong>
                        <br />
                        Students who are interested in BBM Program and wish to join this course for their higher studies in Management sector must appear and passed the CMAT examination conducted by
                        TU and all the eligible students must have at least Grade “C” in the higher secondary level in all subjects.&nbsp;
                      </p>

                      <p>
                        <strong>Elective Courses:</strong>
                        <br />
                        Entrepreneurship |Accounting | Marketing | Finance | Banking &amp; Insurance | HRM
                      </p>
                    </div>
                  </div>
                  <div class='row'></div>
                </div>
                <div class='col-lg-3'>
                  <div class='course-extra-info'>
                    <div class='title'>
                      <h3>Course Info</h3>
                    </div>
                    <ul>
                      <li>
                        <h5>Total Credit</h5>
                        <span>126 Credits</span>
                      </li>
                      <li>
                        <h5>Course Duration</h5>
                        <span>4 years</span>
                      </li>
                      <li>
                        <h5>Program Duration</h5>
                        <span>4 years | 8 Semesters</span>
                      </li>
                      <li>
                        <h5>Course Type</h5>
                        <span>No</span>
                      </li>

                      <li>
                        <h5>Total Cost</h5>
                        <span>Rs. 4,00,000</span>
                      </li>
                    </ul>
                  </div>
                  <div class='course-contact'>
                    <div class='title'>
                      <h3>Get In Touch</h3>
                    </div>
                    <div class='content'>
                      <p>Have questions about studying at Nepalaya? We invite you to connect with our Admissions team to learn more.</p>
                    </div>
                    <Link to={'/contact'} class='link-btn btn-style-5'>
                      Contact Us
                    </Link>
                  </div>
                  <div class='apply-btn mb-5 mt-5'>
                    <a href={'https://schoolworkspro.com/npl/admission'} class='link-btn btn-style-1'>
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class='tour-sec-big wow animate__fadeInLeft animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
            <div class='container'>
              <div class='row align-items-center'>
                <div class='col-lg-7'>
                  <div class='sec-title'>
                    <h3 class='title-big text-white wow animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                      Already part of Nepalaya!
                    </h3>
                    <p class='tour-sub-title'>Login to our E-PORTAL, an extension for quality education.</p>
                  </div>
                </div>
                <div class='col-lg-5'>
                  <div class='tour-btn'>
                    <a href='https://schoolworkspro.com/' class='link-btn btn-style-4 m-2'>
                      Go to E-PORTAL
                    </a>
                    <a href='#' class='link-btn btn-style-4 m-2'>
                      Get More Info
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class='course-content-sec sec-main bg-grey'>
            <div class='container'>
              <div class='row'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                    Course Content
                  </h3>
                </div>
              </div>

              <div class='row g-5 row-col-auto'>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Semester1} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Semester-I&nbsp;Syllabus</i>
                    </p>
                  </a>
                </div>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Semester2} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Semester-II Syllabus</i>
                    </p>
                  </a>
                </div>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Semester3} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Semester-III Syllabus</i>
                    </p>
                  </a>
                </div>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Semester4} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Semester-IV Syllabus</i>
                    </p>
                  </a>
                </div>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Semester5} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Semester-V Syllabus</i>
                    </p>
                  </a>
                </div>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Semester6} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Semester-VI Syllabus</i>
                    </p>
                  </a>
                </div>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Semester7} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Semester-VII Syllabus</i>
                    </p>
                  </a>
                </div>
                <div class='col-md-3 d-flex justify-content-center '>
                  <a href={Semester8} target='_blank'>
                    <img src={PdfImage} alt='' style={{ height: '120px' }} />
                    <p class='text-center'>
                      <i>Semester-VIII Syllabus</i>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class='cta-sec sec-main bg-sec' style={{ backgroundImage: `url(${about1})` }}>
            <div class='blue-overlay'></div>
            <div class='container'>
              <div class='row py-4'>
                <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center'>
                  <div class='sec-title'>
                    <h5 class='title-small text-white wow animate__fadeInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                      Learn about your options.
                    </h5>
                    <h2 class='title-big text-white wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                      IS A CAREER IN BBM PROGRAM RIGHT FOR YOU? FIND OUT!
                    </h2>
                  </div>
                </div>
                <div class='col-lg-8 col-md-10 col-sm-12 mx-auto text-center wow animate__fadeInUp animate__delay-500ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                  <div class='cta-contact-box'>
                    <div class='cta-icon'>
                      <i class='fa fa-comments'></i>
                    </div>
                    <div class='cta-content'>
                      <div class='cta-desc text-white'>Talk to an Expert!</div>
                      <div class='cta-title '>
                        <a href='tel:015375203' class='text-white'>
                          {' '}
                          +977 – 1- 5375203 | 5234279{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class='cta-btn mt-4'>
                    <a href='https://schoolworkspro.com/npl/admission' class='link-btn btn-style-2'>
                      Schedule Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='carrer-pathway-sec sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='col-lg-6'>
                  <div class='image image-top wow animate__fadeInLeft animate__delay-500ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                    <img src={AboutImage} alt='' />
                  </div>
                </div>
                <div class='col-lg-6'>
                  <div class='sec-title '>
                    <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                      How this course will help the students?
                    </h3>
                  </div>
                  <div class='list'>
                    <ul style={{ 'text-align': 'justify' }}>
                      <p>Skills you will gain after BBM Degree:</p>

                      <ul>
                        <li>Influential skills</li>
                        <li>Critical thinking skills</li>
                        <li>Entrepreneurial skills</li>
                        <li>Decision making skills</li>
                        <li>Sales &amp; marketing skills</li>
                        <li>Business communication &amp; interpersonal skills</li>
                        <li>Project management skills</li>
                        <li>Team building &amp; teamwork skills</li>
                        <li>Business and academic proposal writing skills</li>
                        <li>Leadership &amp; managerial</li>
                        <li>Business &amp; financial analysis skills</li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='carrer-opportunities sec-main  wow animate__fadeInUp animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
            <div class='container'>
              <div class='row'>
                <div class='carrer-wrapper'>
                  <div class='carrer-content'>
                    <h3 class='title'>Carrer Pathways</h3>
                    <div class='content'>
                      <p></p>
                      <p>Financial Advisor | Financial Analyst | Banker | Financial Advisor| Stock Broker | Human Resource Manager | Investment Advisor | Entrepreneurs | Businessman&nbsp;</p>
                      <p></p>
                    </div>
                  </div>
                  <div class='career-image '>
                    <img src={StudentImage} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default BBM

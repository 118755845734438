import React from 'react'
import Principal from '../images/principal.jpeg'
import Avatar from '../images/avatar.png'
import about1 from '../images/about1.jpeg'
import { Advisor } from '../ImageExport'
import { Expert } from '../ImageExport'

function IndustryExpert() {
  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>Industry Expert</h2>
                <ul class='bread-crumb'>
                  <li class='breadcrumb-item'>
                    <a href='https://nepalaya.edu.np'>Home</a>
                  </li>
                  <li class='breadcrumb-item'>Industry Expert</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class='bod-pg'>
          <section class='chairmain-sec sec-main'>
            <div class='container'>
              <div class='row chairmain-sec-wrapper wow  animate__fadeInUp animate__animated' style={{ visibility: 'visible', 'animation-name': 'fadeInUp' }}>
                <div class='col-lg-4'>
                  {/* <h3 class='title mb-4'>Message from the Principal</h3> */}
                  <div class='img'>
                    <img src={Expert.AsgarAli} alt='logo' />
                  </div>
                </div>
                <div class='col-lg-8'>
                  <div class='content' style={{ 'text-align': 'justify' }}>
                    <p>Dear Students,</p>

                    <p>Welcome to Nepalaya College!</p>

                    <p>
                      My two decades in Nepalese IT industry has given me extensive knowledge about Nepalese job market where industries absordb those scholars who have degree with real career kills
                      in particular. My personal & institutional association with Nepalaya College as an industry expert and placement partner will boost this institution and its graduates into next
                      level after their degree. I will personally involve in the process of transforming you all to a saleable human resource in the national as well as global job market. I humbly
                      request you all to jon Nepalaya with confidence for you secured future.
                    </p>
                  </div>
                  <div class='content-meta'>
                    <h4 class='name'>Asgar Ali</h4>
                    <h5 class='position'>Former Chairman, eSewa Pvt. Ltd.</h5>
                    <h5 class='position'>Former Directory, Fonepay/F1 Soft Int'l Pvt. Ltd.</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default IndustryExpert

import React from 'react'
import { Link } from 'react-router-dom'
import ceo from '../images/ceo.jpeg'
import { Team } from '../ImageExport'

import about1 from '../images/about1.jpeg'

function MessgaeCEO() {
  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>Message from the CEO</h2>
                <ul class='bread-crumb'>
                  <li class='breadcrumb-item'>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li class='breadcrumb-item'>Message from the CEO</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section class='about-us-sec sec-main pb-0'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-12'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                    Message from the CEO
                  </h3>
                </div>
              </div>
              <div class='col-lg-12 col-md-12 ms-auto pb-5'>
                <div class='about-sec-content wow animate__fadeInLeft animate__delay-500ms' style={{ 'text-align': 'justify', visibility: 'visible', 'animation-name': 'fadeInLeft' }}>
                  <div row class='row'>
                    <div class='col-lg-4 col-md-6 justify-content-md-center'>
                      <p>
                        <img alt='' src={Team.SameerDhamala} />
                      </p>
                    </div>

                    <div class='col-lg-8 col-md-6'>
                      <p>Dear Students/Parents,</p>

                      <p>Welcome at Nepalaya:</p>

                      <p>
                        Your Local <strong>“GLOBAL SCHOOL”</strong>
                      </p>

                      <p>
                        Welcome to Nepalaya! At Nepalaya College, it has always been our core principle to give our students the finest of every resource and environment to develop their skills to the
                        extent of perfection. In this process, we are adopting modern teaching learning methodologies with technology enabled classrooms and well-equipped labs and library. Our
                        academic strength, core values, quality of teaching, advanced labs and resources of international standard enable us to be recognized by leading IT companies from the Nepalese
                        market. Our students getting best academic environment and professional course with profession driven trainings to prepare them to compete with the global workforce. Our
                        graduates are the assets for us while they are perfect resources for the companies who choose them for internship and placement in the respective. field where required.
                      </p>

                      <p>
                        It gives me immense pleasure to be a part of this amazing team and be one of the factors in this transformation journey of Nepalaya. This sensation drives me every day to give
                        the best to you all and contribute in the organizational productivity. more meticulously. Come join Nepalaya College and be a part of its success. We look forward to serving
                        you to achieve your career goals
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>Think career. Think future. Think Nepalaya</strong>
                      </p>

                      <p>
                        <strong>Mr. Sameer Dhamala</strong>
                      </p>

                      <p>
                        <strong>CEO, Nepalaya COllege</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default MessgaeCEO

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import about1 from '../images/about1.jpeg'
import naxios from '../nepAxios'
import ContentView from 'react-froala-wysiwyg/FroalaEditorView'
import { STUDENT_HUB } from '../constants/customApi'

function StudentHub() {
  const [studentHub, setStudentHub] = useState(STUDENT_HUB)

  const getStudentHub = async () => {
    try {
      let result = await naxios.get('/pages/student-hub')
      if (result.data.success) {
        setStudentHub(result.data.page)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getStudentHub()
  }, [])

  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>{studentHub?.title}</h2>
                <ul class='bread-crumb'>
                  <li class='breadcrumb-item'>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li class='breadcrumb-item'>{studentHub?.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section class='about-us-sec sec-main pb-0'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-12'>
                <div class='sec-title text-center'>
                  <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                    {studentHub?.title}
                  </h3>
                </div>
              </div>
              <div class='col-lg-12 col-md-12 ms-auto pb-5'>
                <div class='about-sec-content wow animate__fadeInLeft animate__delay-500ms' style={{ 'text-align': 'justify', visibility: 'visible', 'animation-name': 'fadeInLeft' }}>
                  <ContentView model={studentHub?.content} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default StudentHub

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PdfImage from '../images/pdf.png'
import Prospectus from '../pdf/prospectus.pdf'
import SaveImage from '../images/saveimage.png'
import about1 from '../images/about1.jpeg'
import ProspectusCover from '../images/prospectus_cover.png'
import naxios from '../nepAxios'

function Downloads() {
  const [downloads, setDownloads] = useState([])
  const getAllDownloads = async () => {
    try {
      let result = await naxios.get('/downloads')
      if (result.data.success) {
        setDownloads(result.data.downloads)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getAllDownloads()
  }, [])

  return (
    <div>
      <div className='sitewrapper'>
        <div className='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div className=' container'>
            <div className='row'>
              <div className='col-12 pageheader-inner'>
                <h2 className='page-title '>Downloads</h2>
                <ul className='bread-crumb'>
                  <li className='breadcrumb-item'>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li className='breadcrumb-item'>Downloads</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className='about-us-sec sec-main pb-0'>
          <div className='container'>
            <div class='row g-5 row-col-auto'>
              {downloads && downloads.length > 0 ? (
                <>
                  {downloads.map(down => {
                    return (
                      <div class='col-md-4 d-flex justify-content-center'>
                        <a href={`${process.env.REACT_APP_NEPALAYA_BASE}${down.fileUrl}`} target='_blank' download={true} rel='noreferrer'>
                          <img src={PdfImage} alt='' style={{ height: '100px', borderRadius: '5px', border: '1px solid grey' }} />
                          <p class='text-center fs-2'>
                            <b>{down.title}</b>
                          </p>
                        </a>
                      </div>
                    )
                  })}
                </>
              ) : (
                <div class='col-md-4 d-flex justify-content-center'>No Downloads Found</div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Downloads

import React, { useEffect, useState } from 'react'
import About from './components/About'
import Button from 'react-bootstrap/Button'
import MessgaeCEO from './components/MessgaeCEO'
import Faculty from './components/Faculty'
import BOD from './components/BOD'
import StudentHub from './components/StudentHub'
import Modal from 'react-bootstrap/Modal'
import NepalayaClub from './components/NepalayaClub'
import Academics from './components/Academics'
import ECA from './components/ECA'
import AdmissionForm from './components/AdmissionForm'
import Inquiry from './components/Inquiry'
import AllEvents from './components/AllEvents'
import Contact from './components/Contact'
import Notices from './components/Notices'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './components/Home'
import Error from './components/Error'
import naxios from './nepAxios'
// import { ToastProvider } from 'react-toast-notifications'

import IndustryExpert from './components/IndustryExpert'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css'
import Law from './components/Law'
import Humanities from './components/Humanities'
import Management from './components/Management'
import Science from './components/Science'
import MBS from './components/MBS'
import BCA from './components/BCA'
import BBM from './components/BBM'
import BSCIT from './components/BSCIT'
import BBS from './components/BBS'
// import AdvisorForm from './components/AdvisorForm'
import Downloads from './components/Downloads'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Admission from './images/admissionbachelor.png'
import MessageFromPrincipal from './components/MessageFromPrincipal'
import ReasonWhy from './components/ReasonWhy'
import StudentsClub from './components/StudentsClub'

import NoticesContextProvider from './contexts/Notices'
import Gallery from './components/Gallery'
import StudentVoice from './components/StudentVoice'
import Alumni from './components/Alumni'
import FacultyPLUS2 from './components/faculty/facultyPLUS2'
import FacultyBCA from './components/faculty/facultyBCA'
import FacultyBBS from './components/faculty/facultyBBS'
import FacultyBSCCSIT from './components/faculty/facultyBSCCSIT'
import FacultyBBM from './components/faculty/facultyBBM'
import Course from './components/Course'
import MessageBoard from './components/MessageBoard'
import PopupModal from './components/PopupModal'
import { POPUP } from './constants/customApi'
import DynamicFaculty from './components/DynamicFaculty'
import AdministrationDepartment from './components/AdministrationDepartment'

function App() {
  const [popup, setPopup] = useState(POPUP)

  const getPopupModal = async () => {
    try {
      let result = await naxios.get('/popup')
      if (result.data.success) {
        setPopup(result.data.popup)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPopupModal()
  }, [])

  return (
    <>
      <Header />
      {popup &&
        popup.length > 0 &&
        popup.map(p => {
          return <PopupModal popup={p} key={p._id} />
        })}
      <NoticesContextProvider>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/reasons-to-join-nepalaya' element={<ReasonWhy />} />
          {/* <Route path='message/message-ceo' element={<MessgaeCEO />} /> */}
          <Route path='/faculty' element={<Faculty />} />
          <Route path='/administration-department' element={<AdministrationDepartment />} />
          <Route path='/faculty/plus2' element={<FacultyPLUS2 />} />
          <Route path='/faculty/bbm' element={<FacultyBBM />} />
          <Route path='/faculty/bca' element={<FacultyBCA />} />
          <Route path='/faculty/bsccsit' element={<FacultyBSCCSIT />} />
          <Route path='/faculty/bbs' element={<FacultyBBS />} />
          <Route path='/bod' element={<BOD />} />
          <Route path='/message/:slug' element={<MessageBoard />} />
          {/* <Route path='message/industry-expert' element={<IndustryExpert />} /> */}
          {/* <Route path='message/message-principal' element={<MessageFromPrincipal />} /> */}
          <Route path='/academic' element={<Academics />} />
          <Route path='/student-hub' element={<StudentHub />} />
          {/* <Route path='/students-club' element={<StudentsClub />} /> */}
          <Route path='/nepalaya-club' element={<StudentsClub />} />
          <Route path='/eca' element={<ECA />} />
          {/* <Route path='/admission' element={<AdmissionForm />} /> */}
          {/* <Route path='/inquiry' element={<Inquiry />} /> */}
          <Route path='/events' element={<AllEvents />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/notices' element={<Notices />} />
          <Route path='/law' element={<Law />} />
          <Route path='/humanities' element={<Humanities />} />
          <Route path='/management' element={<Management />} />
          <Route path='/science' element={<Science />} />
          <Route path='/mbs' element={<MBS />} />
          <Route path='/bca' element={<BCA />} />
          <Route path='/bbm' element={<BBM />} />
          <Route path='/bscit' element={<BSCIT />} />
          <Route path='/bbs' element={<BBS />} />
          {/* <Route path='/admission' element={<AdvisorForm />} /> */}
          {/* <Route path='/scholarshipquiz' element={<ScholarshipQuiz />} /> */}
          <Route path='/downloads' element={<Downloads />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/students/voice' element={<StudentVoice />} />
          <Route path='/alumni' element={<Alumni />} />
          <Route path='/course/:slug' element={<Course />} />
          <Route path='/faculty/:slug' element={<DynamicFaculty />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </NoticesContextProvider>
      <Footer />
    </>
  )
}

export default App
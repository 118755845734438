import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const PopupModal = ({ popup }) => {
  const [show, setShow] = useState(true)

  const handleClose = () => setShow(false)

  const renderImage = p => {
    if (p.onClickUrl) {
      return (
        <a href={p.onClickUrl}>
          <img src={`${process.env.REACT_APP_NEPALAYA_BASE}${p?.imageUrl}`} alt='' />
        </a>
      )
    }
    return <img src={`${process.env.REACT_APP_NEPALAYA_BASE}${p?.imageUrl}`} alt='' />
  }

  return (
    <Modal size='lg' centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderImage(popup)}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PopupModal

import React from "react";

import GovindaPrasad from "../images/faculty/govindaprasad.jpeg";
import KrishnaDotel from "../images/faculty/krishnadotel.jpeg";
import AmritMaharjan from "../images/faculty/amtrimaharjan.jpeg";
import ARMANALI from "../images/faculty/armanali.jpeg";
import BIJAYMOKTAN from "../images/faculty/bijaymoktan.jpeg";
import DAMBARPANDEY from "../images/faculty/dambarpandey.jpeg";
import RAJUKAFLE from "../images/faculty/rajukafle.png";
import ShailaMitra from "../images/faculty/shailamitra.jpeg";
import ShyamShrestha from "../images/faculty/shyamshrestha.png";
import about1 from "../images/about1.jpeg";

import { Team } from "../ImageExport";
import Avatar from "./Avatar";

function Faculty() {
  return (
    <div>
      <div class="sitewrapper">
        <div
          class="pageheader-wrapper"
          style={{ backgroundImage: `url(${about1})` }}
        >
          <div class=" container">
            <div class="row">
              <div class="col-12 pageheader-inner">
                <h2 class="page-title ">Faculty and Staffs</h2>
                <ul class="bread-crumb">
                  <li class="breadcrumb-item">
                    <a href="./">Home</a>
                  </li>
                  <li class="breadcrumb-item">Faculty and Staffs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="faculty-pg">
          <section class="sec-main">
            <div class="container">
              <div class="row">
                <div class="sec-title text-center col-lg-9 col-12 mx-auto">
                  <h3
                    class="title-big wow  animate__bounceInDown animate__delay-200ms"
                    style={{
                      visibility: "visible",
                      "animation-name": "bounceInDown",
                    }}
                  >
                    Our Faculty Members
                  </h3>
                </div>
                <p style={{ "text-align": "justify" }}>
                  Currently, Nepalaya College is being led by the team of proven
                  success, visionary, experienced management experts, educators
                  and entrepreneurs to prepare Nepalaya for tomorrow to prove it
                  as A LOCAL GLOBAL SCHOOL. Our management team is entirely
                  focused on facilitating the students with no compromise in
                  quality education to produce globally demanded skilled human
                  resources that can benefit not only the nation but also the
                  entire world. And, to make our vision come true, we have
                  recently partnered with corporate houses, placement partners
                  and renowned international education providers to deliver
                  skill-based courses ever in demand these days. This team is
                  the composition of young, qualified, experienced, dedicated
                  and dynamic individuals who have come together from the
                  multiple academic and professional backgrounds across the
                  country. The administrative responsibility is given to those
                  hands who have already proven their skills in their respective
                  field for more than a decade. Team spirit, cooperation and
                  coordination, effective and on time communication within and
                  beyond the team members are the added features of this team.{" "}
                </p>
              </div>

              {/* <div class="row mt-4">
                <div
                  class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                  style={{
                    visibility: "visible",
                    "animation-name": "fadeInUp",
                  }}
                >
                  <div class="faculty-block">
                    <div class="img">
                      <img src={GovindaPrasad} alt="" />
                    </div>
                    <div class="content">
                      <h3 class="name">Govinda Prasad Paudyal</h3>
                      <span class="title">Patron</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                  style={{
                    visibility: "visible",
                    "animation-name": "fadeInUp",
                  }}
                >
                  <div class="faculty-block">
                    <div class="img">
                      <img src={KrishnaDotel} alt="" />
                    </div>
                    <div class="content">
                      <h3 class="name">Krishna P. Dotel</h3>
                      <span class="title">Principal / Campus Chief</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                  style={{
                    visibility: "visible",
                    "animation-name": "fadeInUp",
                  }}
                >
                  <div class="faculty-block">
                    <div class="img">
                      <img src={Team.RajuKafle} alt="" />
                    </div>
                    <div class="content">
                      <h3 class="name">Raju Kafle</h3>
                      <span class="title">Academic Director</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                  style={{
                    visibility: "visible",
                    "animation-name": "fadeInUp",
                  }}
                >
                  <div class="faculty-block">
                    <div class="img">
                      <img src={BIJAYMOKTAN} alt="" />
                    </div>
                    <div class="content">
                      <h3 class="name">Bijay Moktan</h3>
                      <span class="title">ECA Coordinator</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4 bg-danger border-2 border-top border-secondary" />
              <h2>National Education Board</h2>
              <div class="row mt-4">
                <div
                  class="col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms"
                  style={{
                    visibility: "visible",
                    "animation-name": "fadeInUp",
                  }}
                >
                  <div class="faculty-block">
                    <div class="img">
                      <img src={ShyamShrestha} alt="" />
                    </div>
                    <div class="content">
                      <h3 class="name">Shyam Shrestha</h3>
                      <span class="title">IT Officer</span>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <hr class='my-4 bg-danger border-2 border-top border-secondary' />
              <h2>School of Business</h2>
              <div class='row mt-4'>
                <div class='col-xl-4 col-md-6 col-12  wow animate__fadeInUp animate__delay-200ms' style={{ visibility: 'hidden', 'animation-name': 'none' }}>
                  <div class='faculty-block'>
                    <div class='img'>
                      <img src={ShyamShrestha} alt='' />
                    </div>
                    <div class='content'>
                      <h3 class='name'></h3>
                      <span class='title'></span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Faculty;

import React from 'react'
import about1 from '../images/about1.jpeg'

function Contact() {
  return (
    <div>
      <div class='sitewrapper'>
        <div class='pageheader-wrapper' style={{ backgroundImage: `url(${about1})` }}>
          <div class=' container'>
            <div class='row'>
              <div class='col-12 pageheader-inner'>
                <h2 class='page-title '>Contact Us</h2>
                <ul class='bread-crumb'>
                  <li class='breadcrumb-item'>
                    <a href='./'>Home</a>
                  </li>
                  <li class='breadcrumb-item'>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class='contact-pg'>
          <section class='contact-form sec-main'>
            <div class='container'>
              <div class='row'>
                <div class='sec-title text-center col-lg-9 col-12 mx-auto'>
                  {/* <h3 class='title-big wow  animate__bounceInDown animate__delay-200ms' style={{ visibility: 'visible', 'animation-name': 'bounceInDown' }}>
                    Contact Us
                  </h3> */}
                  <p>
                    Nepalaya College is offering different courses and degrees under the faculties of Science, Management, Humanities &amp; Law in higher secondary level, four years bachelor BSc CSIT,
                    BCA, BBM and BBS programs and year semester-based master program in business studies MBS are running under the affiliation of Tribhuvan University.
                  </p>
                </div>
                <div class='col-lg-9 col-md-12 mx-auto'>
                  <div class='contact-form bg-white wow animate__fadeInUp animate__delay-500ms' style={{ visibility: ':visible', 'animation-name': 'fadeInUp' }}>
                    {/* <form action='' method='post' id='contactForm'>
                      <input type='hidden' name='_token' value='lYYPy3kngwZXNehEKqhyRSJ4f33X0NOTXdqnICGk' />
                      <div class='row'>
                        <div class='col-lg-6'>
                          <div class='form-inner'>
                            <input type='text' class='form-control validate' placeholder='Your Name' aria-label='name' name='name' required='required' />
                          </div>
                        </div>

                        <div class='col-lg-6'>
                          <div class='form-inner'>
                            <input type='email' class='form-control validate' placeholder='Your Email' name='email' />
                          </div>
                        </div>

                        <div class='col-lg-6'>
                          <div class='form-inner'>
                            <input type='text' class='form-control validate' placeholder='Your Phone' name='phone' />
                          </div>
                        </div>

                        <div class='col-lg-6'>
                          <div class='form-inner'>
                            <select class='form-select validate' placeholder='Program' name='program_id' id='COVERAGE_TYPE' required=''>
                              <option value='' disabled='' selected=''>
                                Select Program
                              </option>
                              <option value='1'>BSc CSIT PROGRAM</option>
                              <option value='2'>BBM PROGRAM</option>
                              <option value='3'>BBS PROGRAM</option>
                              <option value='4'>BCA PROGRAM</option>
                              <option value='5'>MBS PROGRAM</option>
                              <option value='6'>SCIENCE</option>
                              <option value='7'>MANAGEMENT</option>
                              <option value='8'>HUMANITIES</option>
                              <option value='9'>LAW</option>
                            </select>
                          </div>
                        </div>
                        <div class='col-lg-12'>
                          <textarea class='form-control input-message validate' placeholder='Your message' rows='7' name='message'></textarea>
                        </div>
                        <div class='col-lg-12 text-center mt-5'>
                          <button id='submit-form' class='link-btn btn-style-1'>
                            {' '}
                            Send message <i style={{ display: 'none' }} id='loader' class='fas fa-sync fa-spin'></i>
                          </button>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='contact-sec'>
            <div class='container-fluid'>
              <div class='row '>
                <div class='col-lg-3 col-md-6 col-sm-12 px-lg-4 single-column  wow animate__fadeInLeft' style={{ visibility: 'visible', 'animation-name': 'fadeInLeft;' }}>
                  <div class='contact-box text-center'>
                    <div class='icon'>
                      <i class='fas fa-map-pin'></i>
                    </div>
                    <div class='content'>
                      <h3 class='contact-title'>Location</h3>
                      <p class='contact-info'>Kalanki, Kathmandu</p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-12 px-lg-4 single-column wow animate__fadeInDown' style={{ visibility: 'visible', 'animation-name': 'fadeInDown' }}>
                  <div class='contact-box text-center'>
                    <div class='icon'>
                      <i class='fas fa-mobile-alt'></i>
                    </div>
                    <div class='content'>
                      <h3 class='contact-title'>Call Us</h3>
                      <a href='tel:977015375203' data-toggle='tooltip' title='Click to Call' class='contact-info'>
                        +977 – 1- 5375203 | 5234279
                      </a>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-12 px-lg-4 single-column  wow animate__fadeInUp' style={{ visibility: 'visible', 'animation-name': 'fadeInUp' }}>
                  <div class='contact-box text-center'>
                    <div class='icon'>
                      <i class='far fa-envelope'></i>
                    </div>
                    <div class='content'>
                      <h3 class='contact-title'>Email Address</h3>
                      <a href='mailto:info@nepalaya.edu.np' data-toggle='tooltip' title='Email Us' class='contact-info'>
                        info@nepalaya.edu.np
                      </a>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-12 px-lg-4 single-column wow animate__fadeInRight' style={{ visibility: 'visible', 'animation-name': 'fadeInRight' }}>
                  <div class='contact-box text-center'>
                    <div class='icon'>
                      <i class='far fa-clock'></i>
                    </div>
                    <div class='content'>
                      <h3 class='contact-title'>Business Hours</h3>
                      <p class='contact-info'>Mon - Fri: 08.00 - Sat: 10.00 - 14.00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class='contact-map'>
            <iframe
              title='nepalaya location'
              class='d-block'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56526.48193111089!2d85.26979141852708!3d27.68932719649779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18650735c313%3A0x179c34a7abffc16d!2sNepalaya%20college!5e0!3m2!1sen!2snp!4v1640075892052!5m2!1sen!2snp'
              height='500'
              style={{ border: '0' }}
              allowfullscreen=''
              loading='lazy'
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact

import React from 'react'

const Avatar = ({ firstName, lastName, circleStyle, initialsStyle, title }) => {
  return firstName || lastName ? (
    <div className={`avatar-circle bg-cl-${firstName.charAt(0).toUpperCase()}`} style={circleStyle} title={title}>
      <span className='initials' style={initialsStyle}>{`${firstName && firstName.charAt(0).toUpperCase()}${lastName && lastName.charAt(0).toUpperCase()} `}</span>
    </div>
  ) : (
    <div className={`avatar-circle`} style={circleStyle} title={title}>
      <span className='initials' style={initialsStyle}>
        N/A
      </span>
    </div>
  )
}

export default Avatar
